export async function registerServiceWorker() {
  if (!('serviceWorker' in navigator)) {
    console.error('Service workers are not supported in this browser.')
    return
  }

  try {
    const registration = await navigator.serviceWorker.register('/sw.js')
    console.log('[Service Worker] Registered:', registration)

    const subscription = await registerPushSubscription(registration)
    console.log('[Push Manager] Subscription:', subscription)

    if (subscription) {
      await sendSubscriptionToServer(subscription)
    }
  } catch (error) {
    console.error('Error during service worker registration:', error)
  }
}

async function registerPushSubscription(registration: ServiceWorkerRegistration) {
  if (!('PushManager' in window)) {
    console.error('Push notifications are not supported in this browser.')
    return null
  }

  try {
    const existingSubscription = await registration.pushManager.getSubscription()
    if (existingSubscription) {
      console.log('[Push Manager] Using existing subscription.')
      return existingSubscription
    }

    const vapidPublicKey = import.meta.env.REACT_APP_WEB_PUSH_VAPID_PUBLIC_KEY || ''
    if (!vapidPublicKey) {
      console.error('Missing VAPID public key. Add it to your environment variables.')
      return null
    }

    const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey)
    const newSubscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: convertedVapidKey,
    })

    console.log('[Push Manager] New subscription created.')
    return newSubscription
  } catch (error) {
    console.error('Error registering push subscription:', error)
    return null
  }
}

function urlBase64ToUint8Array(base64String: string): Uint8Array {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData?.length)

  for (let i = 0; i < rawData?.length; ++i) {
    outputArray[i] = rawData?.charCodeAt(i)
  }

  return outputArray
}

async function sendSubscriptionToServer(subscription: PushSubscription) {
  try {
    const response = await fetch('https://api.flakon.uz/api/subscribe', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ subscription }),
    })

    if (response.ok) {
      console.log('[Push Manager] Subscription sent to server successfully.')
    } else {
      console.error('[Push Manager] Failed to send subscription to server.')
    }
  } catch (error) {
    console.error('[Push Manager] Error sending subscription to server:', error)
  }
}